<template>
  <li role="presentation">
    <component
      :is="tag"
      v-bind="$attrs"
      class="zdropdown-item"
      :class="[linkClasses, { active }]"
    >
      <slot />
    </component>
  </li>
</template>

<script>
import { ZLink } from '#components'

export default {
  inheritAttrs: false,

  props: {
    button: {
      type: Boolean,
      default: false,
    },

    active: {
      type: Boolean,
      default: false,
    },

    linkClasses: {
      type: [Array, String],
      default: '',
    },
  },

  computed: {
    tag() {
      return this.button ? 'button' : ZLink
    },
  },
}
</script>
