<template>
  <div
    v-click-outside="hide"
    class="zdropdown"
  >
    <a
      v-if="nav"
      ref="dropdown"
      class="znav-link zdropdown-toggle"
      @click="toggleShow"
    >
      <slot name="button-content" />
    </a>
    <button
      v-else
      ref="dropdown"
      :class="['zbtn', 'zbtn-md', 'zdropdown-toggle', 'zdropdown-toggle-caret', variantClass]"
      @click="toggleShow"
    >
      <slot name="button-content" />
    </button>
    <ul
      ref="dropdownMenu"
      tabindex="-1"
      class="zdropdown-menu"
      @click.stop="hide"
    >
      <slot />
    </ul>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import ClickOutside from '~/vendor/vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },

  props: {
    variant: {
      type: String,
      default: 'primary',
    },

    nav: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    variantClass() {
      return `zbtn-${this.variant}`
    },
  },

  mounted() {
    this.popper()
  },

  methods: {
    popper() {
      this.popperInstance = createPopper(this.$refs.dropdown, this.$refs.dropdownMenu, {
        placement: 'bottom-end',
        modifiers: [],
      })
    },

    toggleShow() {
      if (this.$refs.dropdownMenu.getAttribute('data-show') != null) {
        this.hide()
      }
      else {
        this.show()
      }
    },

    show() {
      this.$refs.dropdownMenu?.setAttribute('data-show', '')
      this.popperInstance.update()
    },

    hide() {
      this.$refs.dropdownMenu?.removeAttribute('data-show')
    },
  },
}
</script>
